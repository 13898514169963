import * as React from 'react';
import {observer} from 'mobx-react';
import {
  Box,
  Heading,
  Text,
  TouchableBox,
  XStack,
  useIsDesktop,
  useIsLargeMobileAndUp,
} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {useSurfaceParams, useSurfaceOptions} from '../../Surface';

export const NavHeader = <Params extends {}>() => {
  const isDesktop = useIsDesktop();

  const params = useSurfaceParams<Params>();
  const {
    options: {
      headerShown,
      getTitle,
      headerLeft = () => {
        return <NavHeaderLeft />;
      },
      headerRight,
    },
  } = useSurfaceOptions<Params>();

  if (!headerShown) {
    return null;
  }

  return (
    <Box
      zIndex={headerRight ? 1 : 0}
      position="relative"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      // NOTE: New design
      // height={{default: 64, desktop: 88}}
    >
      {headerLeft(params)}
      <XStack>
        <Heading variant={isDesktop ? '$heading-01' : '$heading-02'}>
          {getTitle(params)}
        </Heading>
        {headerRight?.(params)}
      </XStack>
    </Box>
  );
};

const NavHeaderLeft = observer(() => {
  const {t} = useTranslation();

  const isLargeMobile = useIsLargeMobileAndUp();

  const isDesktop = useIsDesktop();

  return (
    <TouchableBox
      position="absolute"
      // NOTE: New design
      // left={isDesktop ? 24 : 16}
      left={isDesktop ? 0 : 16}
      flexDirection="row"
      alignItems="center"
      gap={8}
      onPress={SHARED_ROUTER_SERVICE.goBack}
      testID="BACK_BUTTON"
    >
      <Box
        backgroundColor="$ui-background"
        width={32}
        height={32}
        borderRadius={16}
        justifyContent="center"
        alignItems="center"
      >
        <Icon name="chevron_left" size={24} color="$text-02" />
      </Box>
      {isLargeMobile && (
        <Text variant="$body-01" color="$text-02">
          {t('common.actions.go_back')}
        </Text>
      )}
    </TouchableBox>
  );
});
