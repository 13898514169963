import {action, computed} from 'mobx';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {webTickerConfig} from '@youtoken/ui.coin-utils';
import {i18n} from '@youtoken/ui.service-i18n';
import {
  checkAccountVerifications,
  VerificationItemAccountCode,
  VerificationResource,
} from '@youtoken/ui.resource-verification';
import {
  AddressStatus,
  AuthMeResource,
  KYCStatus,
} from '@youtoken/ui.resource-auth-me';
import {
  FeeWithdrawalResource,
  FeeAllResource,
  FeeItemResponse,
} from '@youtoken/ui.resources-fee';
import {WithdrawalWizardScreenName} from '../../../../../smart';
import {getFeeString} from '../../../utils';

export interface WithdrawalMethodsFeatureArgs {
  ticker?: string;
  variant: 'fiat' | 'crypto' | 'all';
}

export interface FeatureMethodItem {
  method: WithdrawalWizardScreenName;
  type: 'fiat' | 'crypto';
  disabled?: boolean;
}

export class WithdrawalMethodsFeature extends createFeature({
  getKey: (args: WithdrawalMethodsFeatureArgs) =>
    `withdrawalMethods(${JSON.stringify(args)})`,
  getResources: ({ticker}: WithdrawalMethodsFeatureArgs) => {
    return {
      authMe: getResourceDescriptor(AuthMeResource, {}),
      fees: getResourceDescriptor(FeeAllResource, {}),
      ...(ticker && webTickerConfig.TICKERS.FIAT.includes(ticker)
        ? {
            feeWithdrawal: getResourceDescriptor(FeeWithdrawalResource, {
              ticker,
            }),
          }
        : {}),
      verification: getResourceDescriptor(VerificationResource, {}),
    };
  },
}) {
  @computed
  public get ticker() {
    return this.args.ticker;
  }

  @computed
  public get showFiatMethods() {
    return (
      (this.args.variant === 'fiat' || this.args.variant === 'all') &&
      this.fiatMethodsList.length > 0
    );
  }

  @computed
  public get showCryptoMethods() {
    return (
      (this.args.variant === 'crypto' || this.args.variant === 'all') &&
      this.cryptoMethodsList.length > 0
    );
  }

  @computed
  public get showUniversalMethods() {
    return this.args.variant === 'all' && this.universalMethodsList.length > 0;
  }

  @computed
  get bankCardFee(): FeeItemResponse | undefined {
    return this.resources.feeWithdrawal?.data;
  }

  @computed
  get bankCardFeeFormatted() {
    if (!this.bankCardFee?.enabled || !this.ticker) {
      return '';
    }

    const fees = this.resources.fees.getFeeForWithdraw(
      this.ticker,
      'checkoutBankCard'
    );

    if (!fees) {
      return '';
    }

    return getFeeString([this.bankCardFee], this.ticker.toUpperCase(), i18n.t);
  }

  @computed
  get bankWireFeeFormatted() {
    if (!this.ticker) {
      return '';
    }

    const fees = this.resources.fees.data.filter(
      fee =>
        fee.ticker === this.ticker &&
        fee.method === 'withdraw' &&
        ['swift', 'sepa', 'wire'].includes(fee.provider)
    );

    return getFeeString(fees, this.ticker.toUpperCase(), i18n.t);
  }

  @computed
  get shouldForceAllowWithdrawals() {
    const {residenceOrCountry, kycResult, addressResult} =
      this.resources.authMe;
    return (
      residenceOrCountry === 'CAN' &&
      (kycResult === KYCStatus.KYC_RESTRICTION ||
        addressResult === AddressStatus.KYC_RESTRICTION)
    );
  }

  @computed
  get generalWithdrawalEnabled() {
    return this.resources.authMe.products.withdrawalFiat.isEnabled;
  }

  @computed
  get allowCreditCard() {
    const commonConditions =
      this.resources.authMe.products.withdrawalFiat.settings.enabledBankCard &&
      this.generalWithdrawalEnabled;

    if (!this.ticker) {
      return commonConditions;
    }

    // For now, it's only for EUR, but it can be extended to other currencies
    return (
      commonConditions &&
      ['eur'].includes(this.ticker) &&
      this.bankCardFee?.enabled
    );
  }

  @computed
  get allowBankWire() {
    return this.generalWithdrawalEnabled;
  }

  @computed
  get allowUma() {
    const {
      available,
      settings: {enable, enableWithdrawal},
    } = this.resources.authMe.products.uma;

    const isUmaEnabled = available && enable && enableWithdrawal;

    if (this.ticker) {
      const wallet = WalletsResource.getInstanceSafely({})?.getByTicker(
        this.ticker
      );

      return isUmaEnabled && wallet?.umaEnabled;
    }

    return isUmaEnabled;
  }

  @computed
  get allowCryptoDirect() {
    const {available, isEnabled, requirements} =
      this.resources.authMe.products.withdrawalCrypto;

    // NOTE: Withdrawal button should be active to trigger validations from the requirements list even if product itself is not available yet
    if (requirements.length > 0 && isEnabled) {
      return true;
    }

    return available && isEnabled && !requirements.length;
  }

  @computed.struct
  get fiatMethodsList(): FeatureMethodItem[] {
    const list: FeatureMethodItem[] = [];

    if (this.allowCreditCard) {
      list.push({
        method: WithdrawalWizardScreenName.BANK_CARD,
        type: 'fiat',
      });
    }

    if (this.allowBankWire) {
      list.push({
        method: WithdrawalWizardScreenName.BANK_WIRE,
        type: 'fiat',
      });
    }

    if (this.allowUma && this.args.variant !== 'all') {
      list.push({
        method: WithdrawalWizardScreenName.UMA_WITHDRAWAL,
        // NOTE: Despite being shown in the fiat section, UMA is a crypto method.
        type: 'crypto',
      });
    }

    list.sort((a, b) => {
      if (a.disabled && !b.disabled) {
        return 1;
      } else if (!a.disabled && b.disabled) {
        return -1;
      }
      return 0;
    });

    return list;
  }

  @computed.struct
  get cryptoMethodsList(): FeatureMethodItem[] {
    const list: FeatureMethodItem[] = [];

    if (this.allowCryptoDirect) {
      list.push({
        method: WithdrawalWizardScreenName.CRYPTO_DIRECT,
        type: 'crypto',
      });
    }

    if (this.allowUma && this.args.variant !== 'all') {
      list.push({
        method: WithdrawalWizardScreenName.UMA_WITHDRAWAL,
        type: 'crypto',
      });
    }

    return list;
  }

  @computed.struct
  get universalMethodsList(): FeatureMethodItem[] {
    if (this.allowUma) {
      return [
        {
          method: WithdrawalWizardScreenName.UMA_WITHDRAWAL,
          type: 'crypto',
        },
      ];
    }

    return [];
  }

  @action
  public createHandleMethodPress =
    (
      method: WithdrawalWizardScreenName,
      type: FeatureMethodItem['type'],
      onChange: (method: WithdrawalWizardScreenName) => void
    ) =>
    () => {
      const {checkProductAvailability} = this.resources.authMe;

      if (type === 'fiat') {
        if (!checkProductAvailability('withdrawalFiat')) {
          return;
        }
      } else {
        if (!checkProductAvailability('withdrawalCrypto')) {
          return;
        }
      }

      if (this.shouldForceAllowWithdrawals) {
        onChange(method);
      }

      const executeOnChangeWithDisclosureCheck = () => {
        if (!this.resources.authMe.walletsDisclosureSigned) {
          SHARED_ROUTER_SERVICE.navigate('DisclosureWalletsModal', {});
        } else {
          onChange(method);
        }
      };

      if (
        method === WithdrawalWizardScreenName.CRYPTO_DIRECT ||
        method === WithdrawalWizardScreenName.UMA_WITHDRAWAL
      ) {
        executeOnChangeWithDisclosureCheck();
      } else if (
        checkAccountVerifications(this.resources.verification, [
          VerificationItemAccountCode.ADDRESS,
        ])
      ) {
        executeOnChangeWithDisclosureCheck();
      }
    };
}
