import * as React from 'react';
import {Platform} from 'react-native';
import {UmaCreateSurface} from '@youtoken/ui.surfaces-uma';
import {Separator, useWizard} from '@youtoken/ui.elements';
import {i18n} from '@youtoken/ui.service-i18n';
import {cell} from '@youtoken/ui.cell';
import {UmaCellsScrollViewWrapper} from './components';
import {HeaderBase} from '../components';
import {
  type WithdrawalWizardNavigator,
  WithdrawalWizardScreenName,
} from '../index';

const showHeader = Platform.select({
  default: true,
  native: false,
});

export const getUmaCreateTitle = () => {
  return i18n.t('surface.wallets.uma.create');
};

export const UmaCreateCell: React.FC = cell(
  () => {
    const {goBack} = useWizard<
      WithdrawalWizardNavigator,
      WithdrawalWizardScreenName.UMA_CREATE
    >();

    const onFormSubmit = React.useCallback(() => {
      goBack();
    }, [goBack]);

    return (
      <>
        {showHeader && (
          <>
            <HeaderBase onPressBack={goBack}>{getUmaCreateTitle()}</HeaderBase>
            <Separator />
          </>
        )}
        <UmaCreateSurface onSuccess={onFormSubmit} />
      </>
    );
  },
  {
    CellWrap: UmaCellsScrollViewWrapper,
  }
);
